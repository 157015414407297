<template>
  <footer id="footer">
    <div class="content">
    <div class="part">
      <h3>Contactez nous !</h3>
      <ul class="footer-list">
        <li>
          <p>Sur<br><a href="adress-mail@mail.com">adress-mail@mail.com</a></p>
        </li>
        <li>
          <p>Par téléphone au<br>000 / 000.00.00</p>
        </li>
        <li>
          <p>Ou par courrier<br>CP la case 21<br>1000 Une Ville CH</p>
        </li>
      </ul>
    </div>
    <div class="hr" />
    <div class="part">
      <h3>ATOM Contrôles</h3>
      <ul class="footer-list">
        <li>
          <h4>Entreprise</h4>
          <p>Type d'entreprise et ses services</p>
        </li>
        <li>
          <h4>Accréditations</h4>
          <p>Logo Swiss Accréditation</p>
        </li>
        <li>
          <h4>Partenaires</h4>
          <ul>
            <li>La ville</li>
            <li>La commune</li>
            <li>La régie</li>
          </ul>
        </li>
      </ul>
    </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
</script>
