<template>
  <div class="accessibility">
    <a href="#main">Aller au contenu</a>
    <a href="#footer">Aller au informations</a>
  </div>
</template>

<script setup lang="ts">
</script>

<style scoped></style>
